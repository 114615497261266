import * as dompack from "dompack";
import "./overlaymenu.scss";

dompack.onDomReady(() => {
  const overlayMenu = document.querySelector("#overlay-menu");
  const buttons = document.querySelectorAll(".js-toggle-mainmenu");
  const mmMenu = document.querySelector("#overlay-menu-menu");

  const submenus = document.querySelectorAll(".overlay-submenu");
  const ddTogglers = document.querySelectorAll(".overlay-dropdown-toggle");

  let firstitems = document.querySelectorAll(
    ".w-twocols__col--right > .overlay-menu-items"
  )[0].children;

  for (var i = 0; i < firstitems.length; i++) {
    firstitems[i].querySelectorAll(".menu-item__prefix")[0].innerHTML =
      "0" + (i + 1);
  }

  submenus.forEach((submenu) => {
    submenu.setAttribute(
      "style",
      "--overlay-submenu-items: " + submenu.firstChild.childElementCount
    );
  });

  const showMenu = (menu, btns) => {
    menu.classList.add("showmenu");
    overlayMenu.style.zIndex = "99";
    btns.forEach((btn) => {
      btn.classList.add("is-active");
    });
  };
  const hideMenu = (menu, btns) => {
    menu.style.animation = "fadeOutToNone 0.3s ease-out";
    overlayMenu.style.zIndex = "97";
    setTimeout(() => {
      menu.style.animation = "none";
    }, 300);
    menu.classList.remove("showmenu");
    btns.forEach((btn) => {
      btn.classList.remove("is-active");
    });
  };

  buttons.forEach((button) => {
    button.addEventListener("click", () => {
      window.getComputedStyle(mmMenu).display == "none"
        ? showMenu(mmMenu, buttons)
        : hideMenu(mmMenu, buttons);
    });
  });

  ddTogglers.forEach((toggle) => {
    let active = false;

    toggle.addEventListener("click", (e) => {
      if (!active) toggle.classList.add("dropdown-active");

      if (active) toggle.classList.remove("dropdown-active");

      active = !active;
      console.log(active);
    });
  });
});
