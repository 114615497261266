import * as dompack from 'dompack';

dompack.onDomReady(() => {
    for (const elToggleMenu of dompack.qSA('.ww-dropdown-toggle')) {
        elToggleMenu.parentElement.addEventListener('mouseover', evt => {
          dompack.stop(evt);
          elToggleMenu.parentElement.children[1].classList.add('show');
    
          document.addEventListener('mouseout', (e) => {
              elToggleMenu.parentElement.children[1].classList.remove('show');
            }, {once: true});
        });
    }
});