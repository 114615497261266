import * as dompack from "dompack";
import * as mso from "@mod-mso/webdesigns/mso/mso";
// import '@mod-mso_templates/webdesigns/pretium/pretium';
// import './widgets/widgets';
import "./widgets/ovcarousel/ovcarousel";
import "./widgets/ctacarousel/ctacarousel";

import "./components/header/header";
import "./components/overlaymenu/overlaymenu";

import "./nubium.scss";

dompack.onDomReady(() => {
  new mso.MSOSite();
});
